<!--
 * @Description: 微信支付页面
 * @Author: huacong
 * @Date: 2021-06-08 16:23:35
 * @LastEditTime: 2021-07-28 14:17:31
 * @LastEditors: huacong
-->
<template>
  <div class="contentBox">
    <!-- 付款二维码 -->
    <el-dialog
      title="微信付款"
      :visible.sync="codeShow"
      :close-on-click-modal="false"
    >
      <p style="text-align:center;margin: 10px;">
        请您在
        <span style="color:#fa6400">{{ times }}</span>
        秒内完成支付！如已支付可直接返回我的账户页面！
      </p>
      <div style="text-align:center;width: 240px;height:240px;margin: 0 auto;">
        <img :src="codeImg" alt="" style="width: 240px;height:240px;" />
      </div>
    </el-dialog>
    <!-- 主体内容 -->
    <section>
      <div class="bgfff">
        <el-row class="infoTop">
          <el-col :span="8">
            客服工作时间：9:00—18:00
          </el-col>
          <el-col :span="8" class="text-center">
            服务热线：021-50189226
          </el-col>
          <el-col :span="8" class="text-right">
            在线QQ：2413614556
          </el-col>
        </el-row>
        <p class="viptext">
          <i class="el-icon-warning-outline font-red"></i>
          注意：支付过程请勿关闭页面，等待页面自动跳转。
        </p>
        <el-row :gutter="60" style="padding:0px 80px;margin: 0">
          <el-col :span="12" class="typeInfo">
            订单编号:
            <span>{{ orderId }}</span>
          </el-col>
          <el-col :span="12" class="typeInfo">
            下单时间:
            <span>{{ orderTime }}</span>
          </el-col>
          <el-col :span="12" class="typeInfo">
            订单类型:
            <span>{{ orderType }}</span>
          </el-col>
          <el-col :span="12" class="typeInfo">
            充值金额:
            <span>{{ orderFee }}</span>
          </el-col>
          <el-col :span="24" class="typeInfo">
            支付方式:
            <span>
              <img :src="wechatIcon" alt="" class="wxIcon" />
            </span>
          </el-col>
        </el-row>
        <div class="payBox">
          <el-button
            type="primary"
            class="radius-25"
            style="width:200px"
            v-prevent-repeat-click
            @click="payPost"
            >立即支付</el-button
          >
          <el-button
            class="radius-25"
            style="width:200px"
            v-prevent-repeat-click
            @click="cancelOrder"
            >取消订单</el-button
          >
          <p style="text-align:center;padding-top: 16px;">
            <el-checkbox v-model="registWill">
              我已仔细阅读过
              <el-link type="primary"> 《奕翔服务协议》 </el-link
              >，并同意次协议。
            </el-checkbox>
          </p>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      wechatIcon: require("@/assets/icon/loginWechat.png"),
      registWill: false,
      codeShow: false,
      times: 60,
    };
  },
  computed: {
    ...mapState(["orderId", "orderTime", "orderType", "orderFee", "codeImg"]),
  },
  methods: {
    cancelOrder() {
      this.$router.go(-1);
    },
    payPost() {
      if (!this.registWill) {
        this.$message.warning("请确认同意次协议！");
        return;
      }
      this.codeShow = true;
      setInterval(() => {
        this.times = this.times - 1;
        if (this.times == 55) {
          this.getOrderState();
        }
        if (this.times == 50) {
          this.getOrderState();
        }
        if (this.times == 45) {
          this.getOrderState();
        }
        if (this.times == 40) {
          this.getOrderState();
        }
        if (this.times == 35) {
          this.getOrderState();
        }
        if (this.times == 1) {
          this.cancelOrder();
        }
      }, 1000);
    },
    // 请求订单状态
    getOrderState() {
      this.$http
        .post("index/recharge/getorderstatus", { trade_no: this.orderId })
        .then((res) => {
          if (res.data.resultCode === 1) {
            if (res.data.resultData.status == 1) {
              // 未支付
              if (this.times == 1) {
                this.$router.go(-1);
              }
            } else if (res.data.resultData.status == 2) {
              // 支付
              this.codeShow = false;
              this.$message.success("您已支付成功！现在跳转至我的账户！");
              setTimeout(() => {
                this.$router.push("/comPage/vipIndex");
              }, 1400);
            }
          }
        });
    },
  },
};
</script>
<style scoped>
.infoTop {
  padding: 10px 20px;
  border-bottom: 1px solid #e8e8e8;
}
.viptext {
  padding: 20px;
  color: #000;
}
.typeInfo {
  line-height: 50px;
}
.wxIcon {
  width: 36px;
  position: relative;
  top: 13px;
  left: 10px;
}
.payBox {
  text-align: center;
  padding: 50px 0 100px;
}
</style>
